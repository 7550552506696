<!--
 * @Description: 选择SIM卡号弹框
 * @Author: ChenXueLin
 * @Date: 2022-03-04 16:52:36
 * @LastEditTime: 2022-07-01 09:27:52
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 选择sim卡弹框 -->
  <el-dialog
    v-dialogDrag
    title="选择SIM卡"
    :visible.sync="simVisible"
    width="500px"
    :before-close="close"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    custom-class="add-concat-dialog"
  >
    <el-form
      label-width="100px"
      :inline="true"
      :model="simInfo"
      :rules="simInfoFormRules"
      ref="simInfo"
    >
      <el-form-item label="SIM卡号" prop="simNo">
        <e6-vr-select
          v-model="simInfo.simNo"
          :data="simList"
          placeholder="SIM卡号"
          title="SIM卡号"
          clearable
          :props="{
            id: 'simNo',
            label: 'simNo'
          }"
          virtual
          remote
          :is-title="true"
          @filterChange="handleLoadSimFilter"
          @change="handleSimChange"
        ></e6-vr-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="close">取消</el-button>
      <el-button type="primary" @click="selectSim">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { engineerSimList } from "@/api";
import { printError } from "@/utils/util";
import base from "@/mixins/base";
export default {
  name: "",
  components: {},
  data() {
    return {
      simInfo: {
        simNo: "",
        simId: "",
        isSelfOwn: ""
      },
      simInfoFormRules: {
        simNo: [
          {
            required: true,
            message: "请选择SIM卡号",
            trigger: ["blur", "change"]
          }
        ]
      },
      simList: [] //sim卡号下拉框
    };
  },
  props: ["engineerrpId", "simVisible", "equipRow"],
  mixins: [base],
  computed: {},
  watch: {
    simVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadSimData();
        }
      }
    }
  },
  methods: {
    handleLoadSimFilter: _.debounce(async function(val) {
      if (val) {
        this.simInfo = {
          simNo: "",
          simId: "",
          isSelfOwn: ""
        };
        this.loadSimData(val);
      }
    }, 300),
    //加载Sim
    async loadSimData(val) {
      try {
        let res = await engineerSimList({
          id: this.engineerrpId,
          realNo: val
        });
        let simList = res.data;
        //获取客户名称
        if (
          this.equipRow &&
          this.equipRow.bindSimNo &&
          !simList.some(item => item.simNo == this.equipRow.bindSimNo)
        ) {
          simList.unshift({
            isPrivate: this.equipRow.bindIsSelfOwn,
            simId: this.equipRow.bindSimId,
            simNo: this.equipRow.bindSimNo,
            thirdClassName: ""
          });
        }
        this.simList = simList;
      } catch (error) {
        printError(error);
      }
    },
    handleSimChange(flag, node) {
      if (flag) {
        this.simInfo = {
          simNo: node.simNo,
          simId: node.simId,
          isSelfOwn: node.isPrivate
        };
      } else {
        this.simInfo = {
          simNo: "",
          simId: "",
          isSelfOwn: ""
        };
      }
    },
    selectSim() {
      this.$refs.simInfo.validate(valid => {
        if (valid) {
          this.$emit("selectSim", this.simInfo);
        }
      });
    },
    close() {
      this.simInfo = {
        simNo: "",
        simId: "",
        isSelfOwn: ""
      };
      this.$emit("closeSim");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped></style>
